import SvgIcon from 'components/SvgIcon';
import styles from './GroupEntry.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useStores } from 'stores';

const NewDomainAlert = ({ t, lang }) => {
  const [show, setShow] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  let langChange = {
    'zh-CN': 'zh-cn',
    'zh-HK': 'zh-cn',
  };
  let hcLang = langChange[lang] ? langChange[lang] : 'en-us';
  const helpCenter = `https://help.cloverpool.com/hc/${hcLang}/articles/37617380694425-Announcement-on-BTC-com-s-Brand-Upgrade-to-CloverPool`;
  const mainUrl = 'https://cloverpool.com';
  const activityUrl = `https://help.cloverpool.com/hc/${hcLang}/articles/37699204257945`;
  const [seconds, setSeconds] = useState(5);
  const getStorageItem = (key) => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key);
    }
  };
  const setStorageItem = (key, value) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, value);
    }
  };
  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setSeconds(5);
      }, 800);
      return;
    }
    if (seconds === 0) return;
    let newUrl = window['location'].href
      .replace('pool.btc.com', 'cloverpool.com')
      .replace('btc.com', 'cloverpool.com');
    //每次进入都弹层
    // if (getStorageItem('newDomainAlert') === 'true') {
    //   return;
    // }
    // setStorageItem('newDomainAlert', 'true');
    setNewUrl(newUrl);
    let timer = setTimeout(() => {
      let sec = seconds - 1;
      setSeconds(sec);
      if (sec === 0 && show) {
        clearTimeout(timer);
        window['location'].href = newUrl;
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [seconds, show]);
  if (!show) {
    return <></>;
  }
  return (
    <Modal
      wrapClassName={styles['newDomainAlert']}
      open={show}
      onCancel={() => setShow(false)}
      maskClosable={false}
      width="500px"
      centered
      title={
        <div className="flex justify-between items-center">
          <div className={`text-center ${styles['newDomainAlert-title']}`}>
            <ExclamationCircleFilled
              style={{ color: '#d33a38', fontSize: 27 }}
            />
            {t('common:topNav.newDomainAlert.title', { lng: lang })}
          </div>
        </div>
      }
      footer={null}
    >
      <div title="">
        <div className={styles['newDomainAlert-desc']}>
          <div>{t('common:topNav.newDomainAlert.desc.one', { lng: lang })}</div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.desc.two" // optional -> fallbacks to defaults if not provided
              values={{ link: helpCenter }}
              components={[
                <span key={1} style={{ fontWeight: 'bold' }} />,
                <a
                  target="_blank"
                  href={helpCenter}
                  key={0}
                  rel="noreferrer"
                />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.desc.three" // optional -> fallbacks to defaults if not provided
              values={{ link: mainUrl }}
              components={[
                <span key={1} style={{ fontWeight: 'bold' }} />,
                <a href={mainUrl} key={0} />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.desc.four" // optional -> fallbacks to defaults if not provided
              components={[
                <span key={1} style={{ fontWeight: 'bold' }} />,
                <a
                  target="_blank"
                  href={activityUrl}
                  key={0}
                  rel="noreferrer"
                />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          {/* <div>{t('common:topNav.newDomainAlert.desc.three')}</div> */}
          <div>
            {t('common:topNav.newDomainAlert.desc.five', { lng: lang })}
          </div>
        </div>
      </div>

      <div className={styles['newDomainAlert-btn']}>
        <Button href={newUrl} type="primary" size="md" shape="round" ghost>
          <Trans
            i18nKey="common:topNav.newDomainAlert.autoJump" // optional -> fallbacks to defaults if not provided
            values={{ seconds: seconds }}
            components={[<span key={1} style={{ color: 'red' }} />]}
            t={t}
            tOptions={{ lng: lang }}
          />
        </Button>
      </div>
    </Modal>
  );
};
const GroupEntry = () => {
  const { t } = useTranslation('common');
  const { appStore: store } = useStores();
  const { lang } = store;
  return (
    <>
      <a
        className={`${styles['group-entry']} ${styles['xs-hide']}`}
        href="https://cloverpool.com"
      >
        <div className={styles['wordContaner']}>
          {t('topNav.viewAndCollectDesc', {
            lng: lang,
          })}
          <span className={styles['about']}>
            {t('topNav.viewAndCollect', {
              lng: lang,
            })}
            &nbsp;
            <SvgIcon iconName="icon_arrow_down" className="icon-arrow-right" />
          </span>
        </div>
      </a>
      <NewDomainAlert t={t} lang={lang} />
    </>
  );
};
export default GroupEntry;
