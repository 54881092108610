import React, { useEffect, Suspense } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useStores } from 'stores';
import qs from 'qs';
import routes from './pages';
import Layout from 'components/Layout';
import NotFound from './pages/Warning/NotFound';
import { ConfigProvider } from 'antd';
import MetaTags from 'react-meta-tags';
import './styles/antd.reset.less';
import './styles/main.scss';
import { LOCALES_MAP } from 'common/config/localeConfig';
import NoData from './components/widget/NoData';
function RouteAccessCheck({
  children,
  routeConfig,
  userStore,
  redirectTo,
  ...props
}) {
  const { isUserReadOnly, isAuthenticated, hasSubAccount } = userStore;
  const { rejectWatcher, isDependSubAccount } = routeConfig;

  if (isUserReadOnly) {
    if (rejectWatcher) {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
    } else {
      return children;
    }
  } else if (isAuthenticated && isDependSubAccount && !hasSubAccount) {
    return <Redirect to={'/welcome'} />;
  } else if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/',
          hash: '#login',
          state: { from: props.location },
        }}
      />
    );
  } else {
    return children;
  }
}

const isLocalePath = (pathname, lang) => {
  const bool = Object.keys(LOCALES_MAP).some((key) => {
    return (
      lang === key &&
      LOCALES_MAP?.[key].explorerPath.includes(pathname?.split('/')?.[1])
    );
  });
  return bool;
};

const App = () => {
  const { appStore: store, userStore } = useStores();

  const { lang } = store;
  const { accountInfo, isUserReadOnly } = userStore;

  let location = useLocation();
  let history = useHistory();

  const poolUrl = process.env.REACT_APP_POOL_URL;

  useEffect(() => {
    /**
     * 为了解决在不同tab页或不同窗口，切换子账户导致页面数据的不一致的问题
     * 在切换子账户的时候，localStorge 存储的accountInfo会发生变化
     * 监听这个变化，如果变化后的puid和当前不活跃页面的puid值不一样
     * 在不活跃的页面用localStorage中新的 accountInfo 去刷新整个页面
     * 刷新前同步修改url中的puid值
     */
    const refreshPageData = (e) => {
      if (e.key === 'accountInfo' && !document.hasFocus() && !isUserReadOnly) {
        let refreshedAccountInfo = JSON.parse(e.newValue);
        let refreshedPuid = refreshedAccountInfo?.puid?.toString();
        if (refreshedPuid && userStore.accountInfo?.puid) {
          // && refreshedPuid !== userStore.accountInfo?.puid?.toString()
          const urlPuid = qs.parse(location.search, {
            ignoreQueryPrefix: true,
          })?.puid;
          if (urlPuid !== refreshedPuid) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set('puid', refreshedPuid);
            window.location.replace(
              `${window.location.origin}${
                window.location.pathname
              }?${searchParams.toString()}`,
            );
          }
        }
      }
    };
    window.addEventListener('storage', refreshPageData);
    return () => {
      window.removeEventListener('storage', refreshPageData);
    };
  }, []);

  useEffect(() => {
    function localeRouterJump() {
      const pathname = location.pathname;
      const localLang = localStorage.lang;
      Object.keys(LOCALES_MAP).forEach((lang) => {
        if (isLocalePath(pathname, lang)) {
          if (localLang !== lang) {
            store.setLocaleLang(lang);
            return;
          }
        }
      });
    }

    localeRouterJump();
  }, [store, location]);

  useEffect(() => {
    userStore.getAccount(() => {
      if (userStore.isUserReadOnly) {
        userStore.getUserAuthority();
      }
    });
  }, [userStore]);

  history.listen((location, action) => {
    if (action === 'POP') {
      let qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      let urlPuid = qsParams.puid;
      if (localStorage.getItem('puid') !== urlPuid) {
        userStore.changeAccount({ puid: urlPuid }, false);
      }
    }
  });

  return (
    <Suspense fallback="">
      <ConfigProvider
        locale={LOCALES_MAP[lang]?.antdLocale}
        renderEmpty={NoData}
      >
        <Layout>
          <MetaTags>
            {/* <!-- HTML Meta Tags --> */}
            <meta
              name="description"
              content="CloverPool(formerly BTC.com) pool is a whole new choice for bitcoin miners. CloverPool(formerly BTC.com) pool is with much more stable architecture, much better user experience, much lower fees and much stronger service."
            />
            {/* <!-- Google / Search Engine Tags --> */}
            <meta
              itemProp="name"
              content="CloverPool(formerly BTC.com) Pool, a better bitcoin mining pool"
            />
            <meta itemProp="image" content="" />
            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={poolUrl} />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="CloverPool(formerly BTC.com) Pool, a better bitcoin mining pool"
            />
            <meta
              property="og:description"
              content="CloverPool(formerly BTC.com) pool is a whole new choice for bitcoin miners. CloverPool(formerly BTC.com) pool is with much more stable architecture, much better user experience, much lower fees and much stronger service."
            />
            <meta property="og:image" content="" />
            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="CloverPool(formerly BTC.com) Pool, a better bitcoin mining pool"
            />
            <meta
              name="twitter:description"
              content="CloverPool(formerly BTC.com) pool is a whole new choice for bitcoin miners. CloverPool(formerly BTC.com) pool is with much more stable architecture, much better user experience, much lower fees and much stronger service."
            />
            <meta name="twitter:image" content="" />
          </MetaTags>
          <Switch>
            {routes.map((route) => {
              const { component, ...rest } = route;
              route = {
                ...rest,
              };

              const path = isLocalePath(location.pathname, lang)
                ? `/${location.pathname.split('/')?.[1]}${route.path}`
                : route.path;

              let Component = React.lazy(component);
              if (route.requiresAuth) {
                return (
                  <Route
                    {...route}
                    key={route.path}
                    path={path}
                    render={(props) => (
                      <RouteAccessCheck
                        userStore={userStore}
                        routeConfig={{ ...route, component }}
                        {...props}
                      >
                        <React.Suspense fallback={<></>}>
                          <Component
                            key={Math.random()}
                            puid={accountInfo.puid}
                            {...props}
                          />
                        </React.Suspense>
                      </RouteAccessCheck>
                    )}
                  />
                );
              } else {
                return (
                  <Route key={Math.random()} {...route} path={path}>
                    <Component key={Math.random()} puid={accountInfo.puid} />
                  </Route>
                );
              }
            })}

            <Route key="404" component={NotFound} />
          </Switch>
        </Layout>
      </ConfigProvider>
    </Suspense>
  );
};

export default App;
