import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} 마이닝 수익',
      accountEarning: '계정 수익',
      active: '활성화',
      activeMiner: '활성화 채굴기',
      amoutPaid: '결제 완료',
      announcement: '공지',
      cannotConnect: '연결 불가?',
      urlConfigGpu: ' 일반 그래픽카드 채굴기 지원',
      urlConfigNicehash: ' Nicehash 특별 최적화',
      urlConfigGpuDcr: ' 앤트 채굴기DR3, Claymore, gominer 지원',
      urlConfigNicehashDcr: ' Nicehash , Innosilicon 등의 채굴기 지원',
      customerTitle: '애프터 서비스',
      dailyHashrate: '일간 해시레이트',
      dashboard: '유저 페이지',
      earnMore:
        'BTC.com 마이닝풀은 기타 풀에 비해 얼마나 높은 수익을 얻을 수 있을까요?',
      earning: '{coinType} 수익',
      eventConnected: '연결되었습니다.',
      eventDisconnected: '연결이 끊겼습니다.',
      eventMiner: '채굴기',
      eventName: '개의 정보',
      eventSkip: '스킵 완료',
      eventTitle: '이벤트 공지',
      eventlatestLogs: '이벤트 기록',
      hastrateChart: '해시레이트 차트',
      history: '결제 기록',
      hour: '{n} 시간',
      hours: '시간',
      inActive: '비활성화',
      lastPaymentTime: '지난번 결제 시간',
      markAllRead: '모두 읽음으로 표시',
      minerContent:
        '마이닝 소프트웨어 설정 인터페이스에 진입하여 귀하의 서브 계정(비밀 번호를 비워둘 것을 추천)과 마이닝 주소를 입력하고 저장한 후 exe 프로그램을 실행하면 채굴기가 1분 내에 자동으로 마이닝풀 사이트 페이지에 추가됩니다.',
      minerNameStand:
        '채굴기 명칭 규칙:서브 계정+영문 마침=표+번호, 예를 들어 귀하의 서브 계정이 {sub_account}이면 채굴기 명칭을 {sub_account}. 001, {sub_account}. 002, 이와 같은 방식으로 설정할 수 있으며 한 개의 채굴기 명칭은 채굴기 한 대에 대응됩니다.',
      minerName:
        '채굴기 명칭 설정 참고:"{sub_account}. 001", "{sub_account}. 002".',
      miner: '채굴기',
      miner1: 'I 타입 채굴기',
      miner2: 'II 타입 채굴기',
      miners: '채굴기 수',
      miningAddress: '마이닝 주소',
      miningEarnings: '{coinType} 해시레이트 수익',
      minute: '분',
      more: '더 보기',
      networkDiff: '현재 난이도',
      networkHashrate: '네트워크 해시레이트',
      networkStatus: '네트워크 상태',
      nextDifficult: '다음 난이도 예상',
      noMiner:
        '채굴기를 마이닝풀에 추가하여 채굴을 시작해야 합니다. BTC.com 를 이용하여 채굴할 것을 추천드립니다.',
      btcTools: 'BTC Tool',
      noEvent: '현재 이벤트 공지가 없습니다.',
      nowHashrate: '현재 해시레이트',
      pendingPayouts: '결제 대기 중',
      poolHashrate: '마이닝풀 해시레이트',
      realHashrate: '{  coinType} 실시간 해시레이트',
      realtime: '실시간',
      timeRemain: '거리 조정 남은 시간',
      title: '유저 페이지 - BTC.com Pool',
      today: '오늘 예상',
      unpaid: '잔액',
      yesterday: '어제 실제 수익',
      yesterdayEarnTip: 'UTC 시간 전날 0:00-24:00 사이의 수입을 나타냅니다.',
      todayEarnTip: 'SHA256해시레이트 BTC 마이닝 수익 예상',
      charts: {
        hashrate: '해시레이트',
        minerCharts: '채굴기 해시레이트 차트',
        myCharts: '나의 해시레이트 차트',
        poolCharts: '마이닝풀 해시레이트 차트',
        reject: '거절률',
        time: '시간',
      },
      yesterday_earn: '어제의 수익',
      yesterday_hashhrate: '어제의 유효 해시레이트(거절 미포함)',
      earnings_per_100T: '100T당 수익',
      //직책 성명
      statement:
        '* 귀하는 저희가 제공하거나 제안한 방식 이외의 어떠한 방식으로도 해당 서비스를 사용할 수 없으며 불법적이거나 비합법적인 방식으로 해당 서비스를 이용할 수 없습니다. 또한 어떠한 불법 혹은 비합법적인 이벤트를 지원하는 목적으로 해당 서비스를 이용하지 않을 것임을 분명히 보증하여야 합니다. 본 사이트에 진입하여 해당 서비스를 이용하는 것은 귀하가 소재한 지역의 사법 관할 법률에 따라 합법적으로 해당 서비스를 이용할 수 있는 인사임을 약속하고 보증한 것으로 간주합니다.',
      // 수익 예상
      estimatedEarnings: {
        title: '해시레이트 수익 예상',
        hours24Earnings: '최근 24시간 예상',
        tip24: '코인 유형에 따라 최근 24시간 평균 마이닝 난이도를 계산합니다.',
        realTimeEarnings: '실시간 예상',
        realTimeEarningsTip:
          '코인 유형에 따라 최신 마이닝 난이도를 계산합니다.',
      },
    },
    subAccount: {
      title: '서브 계정 생성 - BTC.com Pool',
      createWorker: '서브 계정 생성',
      noNode: '노드가 선택되지 않았습니다.',
      noCoinType: '코인 유형이 선택되지 않았습니다.',
      selectRegion: '지역 선택',
      setWorker: '서브 계정 명칭 설정',
      enterWorker: '서브 계정 명칭을 입력하세요.',
      enterAddress: '월렛 주소를 입력하세요.',
      coinTypeEx:
        'BTC.com 는 현재 {supportCoins} 마이닝을 지원합니다. 더욱 다양한 코인 유형이 곧 출시될 예정입니다. 기대해주세요.',
      regionEx:
        'BTC.com 는 전세계 지역에 서버를 배치하고 귀하와 가장 가까운 서버를 선택하여 귀하에게 최고의 체험을 제공합니다.',
      workerEx:
        '귀하가 설정한 서브 계정은 선택한 지역에 연동됩니다. 귀하는 최대 2000개 서브 계정을 설정할 수 있으며 서브 계정 명칭에 채굴기 번호를 추가하면 채굴기 설정 페이지의 채굴기 명칭(worker name)이 됩니다. 예: "{name}. 001", "{name}. 002"。',
      workerCaution:
        '* 주의: 동일한 서브 계정은 서로 다른 지역에서 채굴하는 것을 지원하지 않습니다. 귀하가 현재 지역에서 생성한 서브 계정은 기타 지역에 존재하지 않게 됩니다. 만약 귀하가 기타 지역에서 채굴기를 보유하고 있으면 대응되는 지역의 서브 계정을 다시 생성하시기 바랍니다.',
      setAddress: '코인 출금 주소를 입력하세요.',
      save: '생성 및 마이닝하기',
      verifyWorker: '서브 계정은 3-20자리 알파벳 혹은 숫자만을 지원합니다.',
      option: '옵션',
      'optional-BTC':
        '주의: 비트코인 캐시(BCH)레거시 주소는 비트코인 (BTC)주소와 유사합니다. 코인 유실로 인한 손해를 입지 않도록 신중하게 입력하십시오.',
      'optional-BCH':
        '주의: 비트코인 캐시(BCH)레거시 주소는 비트코인 (BTC)주소와 유사합니다. 코인 유실로 인한 손해를 입지 않도록 신중하게 입력하십시오. 새로운 주소를 사용할 것을 추천드립니다.',
      'optional-UBTC':
        '주의: 비트코인 캐시(BCH)레거시 주소는 유나이티드 비트코인(UBTC)주소와 유사합니다. 코인 유실로 인한 손해를 입지 않도록 신중하게 입력하십시오.',
      'optional-SBTC':
        '주의: 비트코인 캐시(BCH)레거시 주소는 슈퍼 비트코인(SBTC)주소와 유사합니다. 코인 유실로 인한 손해를 입지 않도록 신중하게 입력하십시오.',
      'optional-coin':
        '주의: 코인 유실로 인한 손해를 입지 않도록 신중하게  { coin_type } 주소를 입력하십시오.',
      selectCoinType: '코인 유형 선택',
      automatic: '자동 생성',
      recommandWallet: 'BTC.com 월렛을 사용하는 것을 추천드립니다.',
      withdrawToExchange: '거래소로 인출',
      withdrawToWallet: '월렛으로 인출',
    },
    tools: {
      // title: '툴 - BTC.com Pool',
      // tools: '툴',
      // fppsPps: 'FPPS와 전통 PPS 다이나믹 비교 툴',
      // aboutFpps: 'FPPS가 전통 PPS보다 나은 것은?',
      // charts: '다이나믹 데이터, 한 눈에 확인 가능',
      // watchCharts: '클릭하여 비교하기',
      // miniCalc: '마이닝 계산기',
      // miniCalcExpress: '난이도와 코인 가격을 결합하여 해시레이트, 전력소비, 전기요금을 통해 마이닝 이윤을 계산합니다.',
      // miniMiniCalc: '미니 마이닝 계산기',
      // miniMiniCalcExpress: '빠른 마이닝 이윤 계산, 해시레이트, 난이도와 코인 가격만 고려하게 됨',
      // strat: '시작',
      // btcTool: 'BTC.com 마이닝풀 일괄 처리 툴',
      // btcToolIntroduce: '채굴기 백그라운드 설정을 일괄로 처리할 수 있어 빠르고 간편합니다.',
      // download: '클릭하여 다운로드하기'
      btcSmartAgent: 'BTC 스마트 에이전트',
      agentExpress:
        '효율적이고 투명합니다. <br/> 단일 채굴기를 확인할 수 있습니다.',
      agentExpressBanner:
        '효율적이고 투명합니다.  <i style="margin-right:20px"></i> 단일 채굴기를 확인할 수 있습니다.',
      bit: 'bit',
      document: 'windows 버전 도움말 문서',
      poolApp: 'BTC.com 마이닝풀App 풀 기능 App',
      poolRealTime: '실시간으로 마이닝풀, 채굴기 데이터 확인하기',
      poolOpeation: '채굴기 삭제, 수정 등의 조작을 지원합니다.',
      poolComming: '이벤트 알림 기능이 출시되었습니다.',
      poolDownlaod: '클릭하여 다운로드하기',
      title: '툴 - BTC.com Pool',
      tools: '툴',
      fppsPps: 'FPPS와 전통 PPS 다이나믹 비교 툴',
      aboutFpps: 'FPPS가 전통 PPS보다 나은 것은?',
      charts: '다이나믹 데이터, 한 눈에 확인 가능',
      watchCharts: '클릭하여 비교하기',
      miniCalc: '마이닝 계산기',
      miniCalcExpress:
        '난이도와 코인 가격을 결합하여 해시레이트, 전력소비, 전기요금을 통해 마이닝 이윤을 계산합니다.',
      miniMiniCalc: '미니 마이닝 계산기',
      miniMiniCalcExpress:
        '빠른 마이닝 이윤 계산, 해시레이트, 난이도와 코인 가격만 고려하게 됨',
      start: '시작',
      btcTool: 'BTC.com 마이닝풀 일괄 처리 툴',
      btcToolIntroduce:
        '채굴기 백그라운드 설정을 일괄로 처리할 수 있어 빠르고 간편합니다.',
      download: '클릭하여 다운로드하기',
      ipToolsTitle: 'BTC.com 채굴기 IP 일괄 수정 툴',
      ipToolsDes:
        '전체 시리즈 앤트 채굴기, 점유 IP 수정, 마이닝풀 직접 연결 설정을 지원합니다.',
    },
    miners: {
      createGroup: '새로운 그룹 생성',
      deleteGroupTitle: '그룹 삭제',
      editGroupTitle: '그룹 편집',
      all: '전체',
      default: '기본 그룹',
      addGroup: '새로운 그룹 생성',
      addGroupInfo: '주의: 선택한 채굴기가 자동으로 새로운 그룹에 추가됩니다.',
      groupNameLabel: '그룹 명칭',
      addGroupEmpty: '그룹 명칭을 입력해주세요.',
      groupRejected: '실시간 거절률', // Real time rejection rate
      groupNameLimit: '그룹 명칭은 반드시 3-20자 사이여야 합니다.',
      inactive: '비활성화',
      active: '활성화',
      dead: '무효',
      moveTo: '이동',
      remove: '삭제',

      allTooltip:
        '포함 내용:활성화채굴기 ({active}) + 비활성화 채굴기({inactive})',
      inactiveTooltip:
        '10분 이상 계산 결과(Share)를 제출하지 않은 채굴기 수량입니다.',
      activeTooltip: '계산 결과(Share)를 제출 중인 채굴기 수량입니다.',
      deadTooltip:
        '24시간 이상 결제 결과(Share)를 제출하지 않은 채굴기 수량입니다.',

      home: '메인',
      miners: '채굴기',

      workerName: '채굴기 명칭',
      Hashrate: '실시간 해시레이트',
      Rejected: '실시간 거절률',
      dailyHashrate: '일간 해시레이트',
      firstShare: '첫 연결 시간',
      LastShare: '최근 제출 시간',
      groupName: '그룹 명칭',

      delMillTitle: '해당 채굴기 정보를 삭제하시겠습니까?',
      delMillContext:
        '해당 조작은 귀하가 선택한 모든 채굴기를 삭제하게 되오니 신중하게 조작하시기 바랍니다.',
      delMillFailed: '삭제 실패',

      delGroupTitle: ' “{name}” 그룹을 삭제하시겠습니까?',
      delGroupContext: '그룹 내 채굴기를 모두 기본 그룹으로 이동하기',

      moveTitle: '채굴기 이동하기',
      notChecked: '채굴기를 체크하여 선택하세요. 일괄 조작을 지원합니다.',
      moveContext:
        '해당 조작은 전체 선택한 채굴기를 {groupName} 그룹으로 이동합니다.',
      moveFaild: '현재 그룹을 이동할 수 없습니다.',

      searchPlaceholder: '채굴기 명칭을 입력하세요.',
      reportTime: '리포트 생성 시간',
      refresh: '새로 고침',
      editGroupName: '그룹 명칭 수정',
      workListEmpty: '해당 그룹에 채굴기가 없습니다.',
      exist: '해당 그룹은 이미 존재하는 그룹입니다.',
      goOldVersion: '기본 버전으로 돌아가기',
      dataExport: `“{name}” 그룹 해시레이트 데이터를 내보내기`,
      exportTip:
        '내보낼 데이터의 시간 구간을 선택하세요. 최대 30일의 데이터를 내보낼 수 있습니다. 내보내는 파일의 형식은 CSV입니다.',
      exportTip2:
        '당일 해시레이트의 통계 시간은 00:00 - 23:59입니다. 그 중 오늘의 해시레이트의 통계 시간은 00:00 - 내보내기 시간입니다.',
    },
    minerInfo: {
      miners: '채굴기',
      hashrate: '단일 해시레이트',
      runningCondition: '실행 상황',
      min: '분',
      hour: '{n}시간',
      hashrateAxis: '해시레이트',
      rejected: '거절률',
      status: '상태',
      lastShare: '최근 제출 시간',
      lastShareIP: '최근 제출한 IP',
      hashrateChart: '해시레이트 차트',
      realTime: '실시간',
      trans: {
        seconds: '초',
        mins: '분',
        hours: '시간',
        ago: '전',
        ACTIVE: '활성화',
        INACTIVE: '비활성화',
        DEAD: '무효',
      },
    },
    earningHistory: {
      title: '수익 기록 - BTC.com Pool',
      earn: '수익 기록',
      Unpaid: '무급',
      EarningsToday: '파낸 것으로 추정',
      EarningsYesterday: '어제 수익',
      Time: '채굴 날짜',
      NetworkDifficulty: '네트워크 난이도',
      Earnings: '수익',
      Payment: '결제 시간',
      payWithOTCAmount: '{otcType}로 결제한 수익: {amount}',
      Mode: '모드',
      Address: '주소',
      PaidAmount: '수익 액수',
      yesterday: 'UTC 시간 전날 0:00-24:00 사이의 수입을 나타냅니다.',
      unpaidReason: {
        PENDING_NON_ADDRESS: '월렛 주소를 설정하지 않아 잔액으로 입금됩니다.',
        PENDING_CHANGED_ADDRESS:
          '주소를 변경 시 48시간이 경과한 후 결제됩니다. 경고: 만약 본인이 주소를 변경한 것이 아니라면 귀하의 계정(이메일/폰 번호)가 이미 해킹되었을 수 있습니다. 이 경우, 기타 이메일 혹은 폰 번호로 새로운 계정을 만들어 채굴기를 새로운 계정으로 변경해 주시기 바랍니다.',
        PENDING_NOT_ENOUGH:
          '귀하가 설정한 최고 결제 금액(기본: limit )보다 적어 잔액으로 입금됩니다.',
        PENDING_POOL_SETTLE_FAULT:
          '마이닝풀의 원인으로 인하여 일부 금액이 잘못 발송되었습니다. 앞으로는 더 이상 지급되지 않습니다.',
      },
      depositeAmount: '입금 잔액',
      diffChg: '난이도 변화: ',
      equalsPps: 'PPS 환산',
      Chg: '일 변동',
      status: '결제 상태',
      paid: '결제 완료',
      waitingPay: '결제 대기',
      reason: '원인',
      fppsMoreEarn: '{mode} 추가 수익:',
      fppsContain: 'PPS모드에 비해 {mode}모드수익이 약 {pps96}증가하였습니다.',
      noEarningsInformation: '현재 수익 정보가 없습니다.',
      earningDetail: '혜택 세부정보',
      Equivalent: '동일 가치 금액',
      extraProfits: '추가 수익',
      autoSwap: '오토 스왑 결제',
      original: '오리지널 코인 결제',
      Swapped: '교환 완료',
      swapping: '교환 중',
      otcEarningTip:
        '오토 스왑 결제 스마트 풀 모드 목표 코인 수익은 Matrixport 결제일 실제 오토 스왑 환율에 따라 계산됩니다.',
      originEarningTip:
        '오리지널 코인 결제 스마트 풀 모드 목표 코인 수익은 결제일 8:00AM Matrixport의 오토 스왑 환율에 따라 계산됩니다.',
      exportEarningsData: '수익 데이터 내보내기',
      exportTip:
        '최대 180 일의 데이터를 지원할 수 있으며 내보낸 파일 형식은 XLS입니다.',
      originStatusTip:
        '수익이 이미 오토 스왑 기능을 통해 목표 코인 수익으로 교환되었습니다. 자세한 내용은 스마트 풀 모드의 수익 페이지에서 조회하시기 바랍니다.',
      swappingTip:
        '현재 수익이 교환 조작 중이거나 수익이 너무 낮아 최저 교환 최저 금액에 도달하지 못했습니다.',
      swappedTip:
        '수익이 이미 오토 스왑 기능을 통해 목표 코인 수익으로 교환되었습니다. 자세한 내용은 스마트 풀 모드의 수익 페이지에서 조회하시기 바랍니다.',
      // upgrade TODO 收益改版新增
      settlementHashrate: '결제 컴퓨팅 파워',
      addressRatio: '주소 비율',
      addressEarnings: '주소 소득',
      paymentTime: '지불 시간',
      EarningsTodayTip:
        '0:00 UTC부터 현재까지 발굴된 예상 PPS 수익 금액은 실제 수익과 다를 수 있습니다.',
      TimeTip:
        '채굴 날짜는 UTC 시간입니다. 예를 들어 2020년 1월 1일은 UTC 시간으로 2020년 1월 1일 00:00:00-2020년 1월 2일 00:00:00을 나타냅니다.',
      EarningsTip:
        '수익 금액은 수익 분배 방식, 네트워크 전체의 난이도, 결제 컴퓨팅 파워, 블록 기본 보상, 블록 거래 수수료 보상 및 마이닝 풀 비율 등 여러 요소를 기반으로 "BTC.com 정산 센터"에서 계산됩니다. 수익률 계산기에서 계산한 이론적 수익률은 편향될 수 있습니다.',
      // upgrade TODO 邀请新增
      inviteEarning: '리베이트 소득',
      inviteWorkerAccount: '광부 하위 계정',
      inviteRebateRatio: '리베이트 비율',
      inviteRebateAmount: '리베이트 금액',
      rebateExportTip:
        '최대 60일의 데이터를 지원할 수 있으며 내보낸 파일 형식은 XLS 입니다.',
    },
    poolStats: {
      title: '마이닝풀 통계 - BTC.com Pool',
      poolStats: '통계',
      hashrate: '해시레이트',
      coinType: '코인 유형',
      miners: '채굴기',
      found: '마이닝 소득',
      hashrateChart: '해시레이트 차트',
      blocksRelayed: '블록 브로드캐스팅',
      height: '높이',
      time: '시간',
      blocks: '블록',
      blockHash: '블록 해시',
      ddaScore: 'DAA Score',
      reward: '블록 보상',
      day: '일',
      region: '지역',
      lucky: '행운치',
      btc: '비트코인',
      ltc: '라이트코인',
      nmc: '네임코인',
      zec: '제트캐시',
      bcc: '비트코인 캐시',
      bch: '비트코인 캐시',
      eth: '이더리움',
      etc: '이더리움 클래식',
      dcr: 'DCR',
      doge: '도지코인',
      grin: 'GRIN',
      sbtc: '슈퍼 비트코인',
      ubtc: '유나이티드 비트코인',
    },

    // 가디언
    guardian: {
      expiredDeadline: '(:day 자동 삭제)',
      guardianRemark:
        '귀하는 전체 혹은 일부 서브 계정 정보(해시레이트, 채굴기 등을 포함)를 기타 사용자에게 공유할 수 있습니다. 해당 사용자는 확인할 수 있지만 어떠한 정보도 변경할 수 없습니다.',
      createGuardian: '공유 사용자 추가',
      remark: '비고: ',
      user: '유저:',
      manage: '관리',
      status: '지불 상태:',
      theGuardian: '공유 계정:',
      creationDate: '생성 날짜:',
      datePrevious: '최근 사용 날짜:',
      bounded: '연동 완료',
      delete: '삭제',
      deleteGuardianLink: '공유 계정 링크 삭제',
      createGuardianInvitation: '공유 계정 초대 링크 생성',
      guardianAccountManagement: '공유 계정 관리',
      modifyRemark: '비고 명칭 수정',
      validateRemarkEmpty: '비고 명칭을 입력해주세요.',
      enterRemark: '비교 명칭을 입력하세요.',
      noteInformation:
        '팁: 해당 명칭은 서로 다른 공유 계정을 구분하는데 사용됩니다. 상대방의 연락 방식 등 정보를 사용하는 것을 권장합니다.',
      confirm: '확인',
      create: '생성',
      cancel: '취소',
      guardianSuccessfullyCreated:
        '공유 계정 초대 링크를 성공적으로 생성하였습니다.',
      copyLink: '링크 복사',
      authorized:
        '삭제한 후 다른 사용자는 귀하의 서브 계정에 대한 관리 권한을 상실합니다.',
      noteSendGuardian:
        '팁: 해당 링크를 채굴기 관리 수요가 있으며 신뢰할 수 있는 사람들에게 보내 주십시오. 해당 링크는 한 번만 방문할 수 있으며 만약 발송에 실패했을 경우 다시 생성하십시오.',
      close: '닫기',
      subaccount: '서브 계정',
      coinType: '코인 유형',
      region: '지역',
      guardianStatus: '공유 상태',
      guardianSelectExpress:
        '공유 상태 중의 스위치를 통해 귀하는 자유롭게 기타 사용자를 선택하여 서브 계정(기본 설정은 전체 서브 계정을 모두 방문 가능)을 관리할 수 있습니다.',
      invitTitle: '다른 사람에게 공유하기',
      acceptTitle: '다른 사람에게 공유를 수락하기',
      losePower:
        '삭제한 후 귀하는 상대방 서브 계정의 관리 권한을 상실하게 됩니다.',
      show: '표시',
    },
    // 가디언 연동 초대 링크
    guardianBind: {
      bindingGuardianInvitation: '공유 계정 연동 초대 링크',
      advance: '고급',
      guardianAccountManagement: '공유 계정 관리:',
      confirmGuardianBind: '가디언 확인 및 계정 연동',
      remark: '비고 명칭',
      enterRemark: '비교 명칭을 입력하세요.',
      suggestName:
        '상대방의 이름을 사용할 것을 권장합니다. 통합 관리에 용이하며 만약 입력하지 않을 경우 기본으로 상대방의 폰 번호 혹은 이메일을 사용합니다.',
      guardian: '연동',
      cancel: '취소',
      confirm: '확인',
      manageView: '귀하의 모든 서브 계정을 관리하시겠습니까?',
      suggestUsingName:
        '상대방의 이름을 사용할 것을 권장합니다. 통합 관리에 용이하며 만약 입력하지 않을 경우 기본으로 상대방의 폰 번호 혹은 이메일을 사용합니다.',
      sms: '폰',
      mail: '이메일',
      code: '{mode}인증 코드',
      send: '발송하기',
    },
    account: {
      subAccountManage: '서브 계정관리',
      currentAccount: '계정',
      locationRegion:
        '귀하는 현재 <strong>{coin}</strong> <strong>{region}</strong> 서버에 있습니다.',
      locationEx:
        '각 서브 계정은 고정된 코인 유형과 지역에 대응되며 한 번 생성된 다음부터는 코인 유형과 지역을 수정할 수 없습니다. 만약 기타 필요한 상황이 있을 경우 새로운 서브 계정을 생성할 수 있습니다.',
      general: '일반 버전 관리',
      advance: '프로 버전 관리',
      create: '서브 계정 생성',
      algorithm: ' 알고리즘',
      oneButtonSwitch: '전부 변경',
      addOthersCoins: '기타 알고리즘 코인 유형 추가',
      supportCoins: '지원',
      hiddenSubAccounts: '서브 계정 관리 숨기기',
      hashAlert: '양호',
      viewHideSubAccount: '숨겨진 서브 계정 확인',
      addSubAccount: '서브 계정 추가',
      recover: '복원',
      latestAlert: {
        hashrate: '실제 해시레이트 {symbol} {expect} {unit}',
        miners: '실제 채굴기 수량 {symbol} {expect} 대',
      },
    },
    settings: {
      subAccount: '서브 계정',
      currency: '코인 유형',
      region: '지역',
      walletAddressAndMinimumPayment: '월렛 주소',
      walletAddress: '월렛 주소',
      shareData: '공유 계정',
      shareDataTip:
        '귀하는 귀하의 하위 계정 정보의 전체 또는 일부를 볼 수만 있고 수정할 수 없는 다른 사람들과 공유할 수 있습니다. 읽기 전용 모드에서 다른 사람의 하위 계정 정보의 전체 또는 일부를 받을 수도 있습니다.',
      alertSettings: '경고 설정',
      mywatcher: '감시자 설정',
      apikey: 'API KEY',
      payWithBtc: 'BTC로 결제',
      //wallet address & minimum payment
      setAddress: '{coinType} 월렛 주소를 설정하세요.',
      address: '월렛 주소',
      createAddress: '새로운 주소 추가',
      modifyAddress: '주소 변경',
      modify: '수정',
      minPay: '최소 결제 금액',
      lastUpdateTime: '주소 수정 시간',
      updateBitconAddressTip:
        '주의: 자금 안전을 위해 이번 조작을 진행 후 48 시간 내에 출금이 일시 정지되며 귀하의 수익은 잔액으로 보관되어 48시간 후의 첫 결제일에 결제됩니다.',

      old: '기존 주소',
      new: '새로운 주소',
      continue: '다음',
      addAddress: '입력',
      enterCode: '인증 코드를 입력하세요.',
      newBit: '귀하의 새로운 주소',
      way: '이번 수정을 확인하기 위해 인증 코드를 발송합니다. 인증 코드를 선택하세요.',
      sendVerifyCode: '인증 코드를 {contactType}에 발송하였습니다.',
      sms: '폰',
      mail: '이메일',
      mailVia: '인증 코드를 이메일로 발송',
      smsVia: '인증 코드를 폰으로 발송',
      errorLater: '잠시후에 다시 시도하세요.',
      updateSuccess: '주소 수정 성공',
      // 여러 개의 주소 수금
      multAddress: {
        tag: '여러 개의 주소 지원',
        addMultAddress: '주소 추가',
        adjustProportion: '비율 조정',
        adjustMinPay: '최저 결제 금액 조정',
        address: '주소',
        proportion: '비율',
        remark: '비고',
        lastUpdateTime: '시간 시간',
        operation: '조작',
        emptyText: '월렛 주소가 추가되지 않았습니다.',
        emptyTextForMult:
          '현재 데이터가 없습니다. 주소는 최대 20개까지 추가할 수 있습니다.',
        cancel: '취소',
        nextStep: '다음',
        delete: '삭제',
        modify: '수정',
        newAddressPlaceholder: '새로운 주소',
        addressMaxCountTip:
          '현재 한 개의 서브 계정으로 월렛 주소를 최대로 {maxCount}개 추가할 수 있습니다. 만약 더 많은 주소가 필요하시면 고객센터 support@connectbtc.com 에 연락해 주시기 바랍니다.',
        updateBitconAddressTip:
          '자금의 안전을 위해 본 작업 후 48시간 이내에 지급이 정지되며, 48시간 후 첫 정산일에 미수금이 지급됩니다.',
        emptyRemark: '비고 미추가',
        noAddressForAdjust:
          '아직 주소가 추가되지 않았습니다. 주소를 추가한 후에 비율 또는 최소 결제 금액을 조정하시기 바랍니다.',
        balanceMoveTip:
          '현재 부계정의 미지급 암호화폐는 "새주소 1"로 이체될 예정이니, 동작 확인을 부탁드립니다.',
        confirmModalTitle: '보안 인증',
        authentication: '신분 인증',
        operationConfirm: '조작 확인',
        addAddressConfirmTip:
          '현재 {count}개의 월렛 주소를 추가하고 있는 중입니다.',
        modifyProportionConfirmTip:
          '각 월렛 주소의 비율을 조정하고 있는 중입니다.',
        deleteAddressConfirmTip:
          '주소를 삭제하고 있는 중입니다. <br/>{address}',
        selectBalanceMoveAddress: '상속 주소 선택',
        selectBalanceMoveAddressTip:
          '삭제되는 주소의 미지급 암호화폐는 {balance}{coinType}입니다. 이 작업이 끝나면 24시간 이내에 위에서 선택한 새 주소로 이체됩니다.',
        cannotDeleteAddress: '해당 주소를 삭제할 수 없습니다.',
        deleteAddressRule: '수취 비율이 0.00%인 주소만 삭제할 수 있습니다.',
        // deleteAddressRuleDetail:
        //   '1. 수취 비율이 0.00%입니다. <br/>2. 동일한 서브 계정의 스마트풀 마이닝 주소를 본 주소로 설정하지 않았습니다.',
        // usedBySmart:
        //   '현재 서브 계정의 스마트 풀마이닝이 선택한 것은 현재 주소입니다. 이번 수정을 통해 스마트 풀 마이닝 주소도 함께 수정됩니다.',
        operationSuccess: '조작 성공',
        validate: {
          emptyAddress: '새로운 주소를 입력하세요.',
          emptyAddressForUpdate: '주소를 입력해주세요.',
          emptyProportion: '주소를 입력하세요.',
          emptyProportioAfter: '의 비율',
          proportionInvalidate:
            '각 주소의 비율은 0% 이상이어야 하며 모든 비율의 합은 100%와 같아야 합니다',
          maxCount: '한 서브 계정에 최대 20개 주소까지 설정할 수 있습니다.',
          duplicateAddressPrefix:
            '동일한 주소는 1회만 추가할 수 있습니다. 주소',
          duplicateAddressSuffix: '여러 번 추가되었습니다.',
          limitAddress: 'This type of address is not supported at this time',
        },
      },

      //min pay
      currentMinPay: '현재 최소 결재 금액:',
      minPayAllSubAccount:
        '모든 비트코인(BTC)서브 계정은 동일한 최소 결재 금액을 사용합니다.',

      //Share to watcher
      watcher: '관찰자',
      availableWatchers: '현재 사용 가능',
      watcherExpress:
        '* 관찰자는 아래의 링크를 통해 귀하의 데이터를 방문할 수 있지만 귀하의 계정을 조작 및 변경할 수 없습니다.',
      createWatcher: '신규 관찰자 생성',
      modifyWatcher: '권한 편집',
      authoritySettings: '권한 설정',
      setWatcherName: '관찰자 비교 명칭 설정',
      watcherName: '관찰자 비고 명칭',
      watcherNameTip: '문자 제한 2~20',
      notEmptyWatcherNote: '관찰자 비고 명칭을 입력해주세요.',
      tips: '팁:',
      tip_dashboard:
        '1. 대시보드에는 "미지급" 및 "유료"와 같은 적립 정보가 포함되지 않습니다.',
      tip_earning:
        '2. 수익에는 각 주소의 "미지급" 및 "유료"와 같은 정보가 포함됩니다.',
      moreSettings: '더 많은 설정',
      guardianRemark:
        '귀하는 전체 혹은 일부 서브 계정 정보(해시레이트, 채굴기 등을 포함)를 기타 사용자에게 공유할 수 있습니다. 해당 사용자는 확인할 수 있지만 어떠한 정보도 변경할 수 없습니다.',
      guardian: '공유 계정',
      shareAccountsSetting: '계정 공유',
      accessKey: 'Access Key',
      generateAK: 'Access Key 생성',
      puid: 'PUID',
      apiDoc: 'API 문서',
      confirmDeleteWatcher: '해당 관찰자 서브 계정을 삭제하시겠습니가?',
      watcherAuths_Dashboard: '유저 페이지',
      watcherAuths_Dashboard_Tip: '(수익 정보 미포함)',
      watcherAuths_Miners: '채굴기 페이지',
      watcherAuths_Earnings: '수익 정보',
      watcherAuths_GetCoin: '기프트 코인',

      // Alert setting
      alertItem: '경고 항목 설정',
      alertContacts: '경고 연락 담당자',
      hashrateAlert: '해시레이트 경고',
      minerAlert: '채굴기 수량 경고',
      alertFrequency: '경고 빈도',
      alertFrequencyTip:
        '정책적 이유로 인해 일부 국가에서는 휴대전화 문자 메시지 공지 빈도가 24시간 당 1회로 고정돼 있으니 숙지하시기 바랍니다.',
      warnMeWhenHashrate: '해시레이트 경계선≤',
      warnMeWhenActiveMiner: '활성화 채굴기 수량 경계선≤',
      dontWarnMeTwice: '두 번의 경고 최소 간격',
      hours: '시간',
      apply: '적용',
      operateFailed: '조작 실패!',
      contactsList: '연락 담당자 리스트',
      cancel: '취소',
      ok: '확인',
      save: '저장',
      reset: '리셋',
      newContact: '연락 담당자 추가',
      editContact: '연락 담당자 수정',
      deleteContact: '연락 담당자 삭제',
      confirmDeleteContact: '해당 경고 연락 담당자를 삭제하시겠습니까?',
      name: '호칭/비고',
      phone: '전화',
      email: '이메일',
      validateAlert: {
        nameEmpty: '호칭/비고를 입력해주세요.',
        phoneRegionFormat: '잘못된 지역 코드입니다.',
        phoneFormat: '잘못된 전화번호입니다.',
        emailFormat: '잘못된 이메일입니다.',
        contactEmpty:
          '메일 주소와 전화번호 중 반드시 최소 한 개를 입력해야 합니다.',
      },
      max_email_contacts: '최대 10명의 이메일 연락 담당자를 지원합니다.',
      max_phone_contacts: '최대 5명의 전화 연락 담당자를 지원합니다.',
      enabled_alert_need_hashrate:
        '해시레이트가 검사되지 않아 경고 기능을 열 수 없습니다.',
      alertItemUpdateSuccess: '경고 항목 설정 성공',

      done: '제출하기',

      okay: '확인',
      emptyAddress: '주소를 입력해주세요.',
      invalidAddress: '잘못된 주소입니다.',
      emptyCode: '인증 코드를 비워둘 수 없습니다.',
      wrongCode: '인증 코드 오류',
      emailVia: '이메일로 발송',
      textVia: '문자 메시지로 발송',
      help: '도움말',

      contact: '연락 방식:',
      close: '닫기',
      receivedress: '월렛 주소:',

      todayUpdateMinPay:
        '오늘부터 마이닝 수익의 최소 결제 금액이 {minPay}로 변경되었습니다.',
      updateAddress: '주소 수정 시간:',
      // pay with btc
      payWithBtcTip:
        '* BTC 결제를 오픈한 후, 귀하의 마이닝 수익이 매일 일정한 시간에 비트코인으로 환산되어 수익 기록에 표시되며 아래와 같은 별도의 BTC 결제 주소로 결제됩니다. 환산 시간은 일반적으로 매일 오전 12:00이며 특수한 상황 발생 시 별도로 안내됩니다.',
      convertedCurrency: '교환 코인 유형: ',
      setBtcAddressTip: 'BTC월렛 주소를 설정하세요.',
      btcAddress: '비트코인 주소: ',
      conversionRate: '참고 환률',
      convert: '교환',
      getValue: '획득 가능:',
      conversionTip:
        '* 교환 환률은 참고용으로만 제공되며 실제 결제 결과를 기준으로 합니다.',
      lastUpdate: '최근 업데이트: ',
      on: '열기',
      off: '닫기',
      turnOnPaywith: 'BTC 결제를 오픈하시겠습니까?',
      turnOffPaywith: 'BTC 결제를 닫으시겠습니까?',
      payOn: '열기',
      payOff: '닫기',
      resend: '재발송',
      send: '인증 코드 발송',
      resendTip:
        '인증 코드를 받지 못하였을 경우 잠시 기다리거나 몇 번 더 시도해주세요.',

      // 원 스위치 One Switch
      oneSwitch: {
        attention: '주의 사항',
        optionCurrentMode: '전환할 모드를 선택하세요.',
        switch: '해시레이트를 {destMode}로 전환합니다.',
        switchAnotherMining: ' {destMode} 모드로 전환 중입니다.',
        ethSwitchWarning:
          '경고:귀하의 채굴기가 DAG 전환을 지원하지 않을 경우 수익이 0이 될 수 있으며 수동으로 채굴기를 재부팅해야만 정상으로 회복할 수 있습니다.(앤트 채굴기 E3은 지원하지 않으며 ETC에서 ETH로 전환하면 수동 재부팅이 필요합니다.)',
        ethSwitchWarningLinkTxt: '자세한 사항은 클릭 후 확인하세요.',
        hashsHaveSwitched:
          '해시레이트는  {destMode} 마이닝으로 전환되었습니다. ;',
        checkSubAccount:
          '서브 계정 {subAccountName}- {subAccountRegionNode}를 확인하세요.',
        enter: '입장',
      },
      // 스마트 풀 월렛 주소
      smartAddress: {
        // autoSwapSettlement: '오토 스왑 결제',
        original: '오리지널 코인 결제',
        autoTip:
          '스마트 풀 수익 코인 유형이 매일 목표 코인 유형으로 환산되어 결제됩니다.',
        originalTip:
          '스마트 풀 수익이 스마트 풀이 지원하는 코인 유형 BTC, BCH, BSV로 결제됩니다.',
        originalCurrency: '오리지널 코인 유형',
        targetCurrency: '목표 코인 유형',
        earningsInSmartPool: '스마트 풀 수익',
        activated: '활성',
        unactivated: '비활성',
        modifyTip:
          '스마트 풀 오토 스왑 결제의 목표 코인 유형을 동일한 서브 계정의 동일한 코인 유형 월렛 주소와 연관되고 일치하게 합니다.',
        autoHistory: '역대 수익을 오토 스왑하시겠습니까?',
        notice1:
          '안전상의 이유로 주소 주성 후 48 시간 내에 결제되지 않으며 귀하의 수익은 잔액으로 보관되어 48 시간 후 첫 결제일에 결제됩니다.',
        notice2:
          '오토 스왑 기능을 오픈하면 당일 결제에서 스마트 풀 마이닝 수익이 자동으로 목표 코인 유형으로 환산되며 "확인"을 클릭 시 {fundLink}를 동의하는 것으로 간주합니다.',
        notice3:
          '스마트 풀 모드 월렛 주소를 동일한 서브 계정의 동일한 코인 유형 수익 주소와 연관되고 일치하게 합니다. (만약 여러개의 주소를 설정하였으면 첫 주소와 일치하게 합니다.)',
        originalNotice1:
          '스마트 풀 모드 월렛 주소를 동일한 서브 계정의 동일한 코인 유형 수익 주소와 연관되고 일치하게 합니다. (만약 여러개의 주소를 설정하였으면 첫 주소와 일치하게 합니다.)',
        currentPayoutMode: '현재 결제 모드: {mode}',
        fundLink: `<BTC.com 자금 교환 라이선스 계약>`,
        originBtn: '오리지널 코인 결제 오픈',
        otcBtn: '오토 스왑 결제 오픈',
        otcSuccess:
          '스마트 풀 모드 오토 스왑 결제 기능이 오픈되어 BTC로 수익을 결제합니다.',
        otcModalNotice2: `역대 수익 오토 스왑을 오픈하면 계정 중의 역대 수익은 오토 스왑에 참여합니다. 역대 수익 오토 스왑을 오픈하지 않으면 최근 결제 주기 내의 수익만 오토 스왑에 참여하게 됩니다. `,
        originSuccess:
          '스마트 풀 모드 오리지널 코인 결제 기능이 오픈되었습니다. 해시레이트를 통해 실제 마이닝한 코인 유형이 수익으로 결제됩니다.',
        // 2021-06-04 신규 추가
        mode_otc: '오토 스왑 결제',
        mode_origin: '오리지널 코인 결제',
        settlementMode: '결제 모드',
        settlementModeDescription: '결제 모드 설명',
        originDescription:
          '오리지널 코인 결제:실제 마이닝한 코인 유형으로 결제되며 코인 유형 범위는 BTC, BCH, BSV입니다.',
        autoSwapDescription:
          '오토 스왑 결제: 먼저 스마트 풀 알고리즘에 따라 BTC/BCH/BSV의 3가지 코인 유형으로 다이나믹 마이닝되며 최종적으로 BTC로 일괄 교환해 결제됩니다.',
        settlementSwitch: '결제 모드 전환',
        originalSettlement: '오리지널 코인 결제',
        autoSwapSettlement: '오토 스왑 결제',
        sha256SmartPoolCurrency: 'Sha256스마트 풀코인 유형',
        currency: '코인 유형',
        address: '주소',
        addressRules: '주소 규칙',
        switchAddress: '주소 전환',
        noAddressTip:
          '동일한 서브 계정의 {coin}코인 유형에 주소가 추가되지 않았습니다.',
        noAddressSelected: '주소를 선택하지 않았습니다.',
        selectFrom: '동일한 서브 계정의 {coin} 주소 리스트에서 선택합니다.',
        settingTip:
          '팁: "스마트 풀마이닝월렛 주소"과 "동일한 서브 계정의 인 유형 마이닝 주소"를 구분하려 할 경우, 단일 코인 유형 주소 관리에서 새로운 주소를 추가하고 수금 비율을 0으로 설정한 다음 이곳에서 새로운 주소로 전환하면 됩니다.',
      },
    },
    homepage: {
      btcSmartAgent: 'BTC스마트 에이전트',
      agentExpress: '효율적이고 투명함&emsp;&emsp;&emsp;단일 채굴기 확인 가능',
      bit64: 'Win7및 이상 64비트',
      bit32: 'WinXP 32비트',
      document: 'windows버전 도움말 문서',
      accessLinuxVersion: 'Linux버전을 설치하여 직접 방문',
      cuttingEdgeTechnology: '업계 선도적 핵심 기술',
      latestArchitecture: '최신 오리지널 아키텍처',
      openSourceCode: '공정하고 공개된<br/>오픈 소스코드',
      highTestPressure: '굳건한<br/>초고압 스트레스 테스트',
      lowOrphanedRate: '신속한<br/>최저 고압 블록률',
      transparentAgent: '<br/>최신 주변 소프트웨어',
      standardsServices: '마이닝 서비스의 새로운 표준',
      customerHotline: '고객센터 전화',
      highHashrateConsultation: '대형 해시레이트 상담',
      professionalCustomerService: '전문적인 고객센터를 갖춘 마이닝풀',
      bestVipServer: '업계 최고 사양을 갖춘 VIP서버',
      oneToOne: '업계 최고의 일대일 케이스 분석',
      appAvailable: '마이닝풀App앱을 통한 마이닝을 간편하게 관리',
      earningsTimelyEveryday: '매일 정해진 시간에 수익 수령',
      transnationalTeamOperation: '국제적 팀이 지원하는 마이닝 풀',
      currencies: '코인 유형',
      dailyEarnings: '일간 수익',
      hashrate: '해시레이트',
      hashrateChart: '해시레이트 차트',
      explorer: '브라우저',
      prices: '코인 가격',
      earningsModels: '수익 모드',
      minPayment: '최소 결제 금액',
      help: '도움말',
      notePaytime: '매일 0:00-8:00(UTC) 어제 수입 지급',
      yuanKwhElectric: '위안/와트·시(전기요금)',
      todayEarnings: '일간 수익 약:',
      piece: '대',
      signIn: '로그인',
      signUp: '회원 가입',
      power: '전력 소비',
      hashrateProfitEst: '해시레이트 수익 참고',
      hashrateProfitEstTips:
        'There may be a deviation from your actual earnings, which is for reference only.',
      miningProfitEst: '주류 채굴기 수익 참고',
      fee: '수수료',
      desc: '세계 최고의 멀티 코인 종합 서비스 마이닝풀',
      tutorial: '튜토리얼',
      diff: '현재 난이도',
      nextDifficultyEstimated: '다음 난이도 예측',
      dateToNextDifficulty: '다음 난이도 남은 시간',
      Algorithm: '알고리즘',
      backup: '예비 포트',
      whyMe: {
        title: 'CloverPool(formerly BTC.com) 마이닝풀를 선택해야 하는 이유',
        leadingTechnology: '첨단 기술',
        convenientManagement: '간편 관리',
        revenueAssurance: '수익 보장',
        professionalServices: '전문 서비스',
        desc1: `오픈 소스코드이며 무료 채굴장 관리 소프트웨어와 공식 맞춤형 오버클록 펌웨어로 수익을 대폭 향상시키며 블록체인 데이터와 기술 서비스를 제공하는 업체입니다. `,
        desc2:
          '간소화된 APP와 웹페이지를 통해 모바일 번호/이메일로 쉽게 회원에 가입할 수 있으며 독립적인 알림 기능, 수익 주소, 채굴기 그룹과 데이터를 공유하는 관찰자 링크를 제공합니다.',
        desc3: `실제적이고 투명한 마이닝 데이터, 선진적인 FPPS 수익 모드, 그리고 강력한 결제 센터를 갖추고 있으며 정해진 시간에 결제됩니다.`,
        desc4: `전문적인 고객센터 팀이 업계 최고 수준의 맞춤형 VIP 서비스를 제공하며 업계 최고 연구진들이 일대일로 케이스를 분석합니다. `,
      },
      app: {
        title: 'CloverPool(formerly BTC.com) App',
        description: '글로벌 선도 멀티 코인 종합 마이닝풀',
        property1:
          '멀티 코인 마이닝 지원, 다중 서브 계정 관리, 관찰자 링크 공유를 통한 간편한 관리 지원',
        property2:
          '포괄적인 코인 데이터의 실시간 표시, 더욱 신속한 마이닝 동향 파악',
        property3: '보다 원활한 사용자 환경을 위한 최신 인터랙티브 인터페이스',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: 'ETH의 결제 모드는 FPPS/PPS+듀얼 모드이며 현재 모드는 FPPS 모드입니다. 플랫폼이 체인 거래 비용, 마이닝풀 해시레이트 점유율, 시장 상황 및 사용자 수익 등 종합적인 요인에 따라 결제 모드를 조정합니다.',
            'PPS+':
              'ETH의 결제 모드는 FPPS/PPS+듀얼 모드이며 현재 모드는 PPS모드입니다. 플랫폼이 체인 거래 비용, 마이닝풀 해시레이트 점유율, 시장 상황 및 사용자 수익 등 종합적인 요인에 따라 결제 모드를 조정합니다.',
          },
        },
        ltc: {
          giftCoin: '+DOGE,BEL',
        },
      },
      globalZone: 'Global',
      zilDiff1: 'Shard',
      zilDiff2: 'DS',
      etcMinerAddressTips: 'ETC만을 채굴하는 장비는 이 채굴 주소로 연결됩니다.',
      zilMinerAddressTips:
        'ETC와 ZIL 이중 채굴 장비가 이 채굴 주소에 연결되어 있습니다.',
    },
    advanceAccount: {
      title: '서브 계정 관리 - BTC.com ',
      all: '전체 코인 유형',
      unit: '단위',
      subAccount: '서브 계정',
      coin: '코인 유형',
      hashrate: '해시레이트',
      allMiners: '전체 채굴기',
      guard: '비고 명칭',
      activeMiners: '활성화',
      inactiveMiners: '비활성화',
      deadMiners: '무효',
      region: '지역',
      more: '더 보기',
      setting: '설정',
      totalHashrate: '총 해시레이트',
      search: '검색',
      status: '상태',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: '오늘부터 BTC.com 마이닝풀에서 마이닝(비트코인 캐시, 비트코인) 시, 네임코인(NMC), 엘라스토스(Elastos)를 무료로 증정합니다.',
        bch: '오늘부터 BTC.com 마이닝풀에서 마이닝(비트코인 캐시, 비트코인) 시, 네임코인(NMC), 엘라스토스(Elastos)를 무료로 증정합니다.',
        ltc: '오늘부터 BTC.com 마이닝풀에서 마이닝(라이트코인LTC) 시, 도지 코인을 무료로 증정하며 PPLNS방식으로 결제됩니다.',
        bcha: `<p>BCHA(BCHABC)는 BCH에서 파생된 코인입니다. 자세한 내용은 다음 사이트를 참조하십시오. <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a></p>
          <p><b>인출 시간:</b><br/>2020년 12월 7일 오전 08:00 부터 2021년 1년 7월 오전 08:00까지 (싱가포르 시간)
          </p>`,
      },
      beta: '클로즈 베타 중',
      whatsThis: '알아보기',
      address: '주소:',
      newAddress: '새로운 주소',
      addressComplete:
        '귀하의 수익을 보호하기 위해 가능한 빨리 무료 코인을 받을 수 있는 주소를 입력하세요.',
      bchaAddressComplete:
        '2021년 1월 7일 이후 주소를 입력하지 않은 사용자의 BCHA는 초기화되며 재지급되지 않습니다. 귀하의 수익을 보호하기 위해 가능한 빨리 무료 코인을 받을 수 있는 주소를 입력하세요.',
    },
    watcher: {
      expired: '관찰자 권한 기한 만료',
      incorrect: '관찰자 링크 오류',
    },
    activity: {
      pointsToday: '오늘 포인트',
      pointsSum: '누적 포인트',
      ranking: '랭킹',
      earnings: '수익',
    },
    minerRanking: {
      title: '채굴기 수익 랭킹',
      electric: '일간 전기요금',
      dailyEarning: '일간 수익',
      priceShutdown: '셧다운 코인 가격',
      ALL: '전체',
      CNY: '인민폐 CNY',
      USD: '미국달러 USD',
      desc: '수익 높은 순',
      asc: '수익 낮은 순',
      electricPercent: '전기요금 비율',
      searchTip: '채굴기를 입력하세요.',
      updated: '업데이트',
      coin: '코인 유형',
      emptyShow: '조건에 부합하는 채굴기가 없습니다.',
      rank: '랭킹',
      miner: '채굴기',
      power: '전력 소비',
      hashrate: '해시레이트',
      rev24h: '일간 생산',
      energy: '전기요금 지출',
      incomeWithdoge: 'Inclusive of DOGE',
      incomeWithdogeAndBel: 'Inclusive of DOGE+BEL',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
