import flatten from 'flat';
const commonMessage = {
  common: {
    locale: 'Deutsch',
    header: {
      home: 'Pool Home',
      dashboard: 'Dashboard',
      getCoin: 'Erhalte Coin',
      help: 'Hilfe',
      miners: 'Miner',
      poolStats: 'Pool-Statistik',
      commissionEarnings: 'Provisionse',
      miningEarnings: 'Minengewinn',
      service: 'Service',
      tools: 'Tools',
      setting: 'Einstellungen',
      guardian: 'Teilen',
      guardianPermission:
        'Sie sind ein autorisierter Benutzer mit schreibgeschütztem Zugriff auf das Konto.',
      watcher: 'Beobachter',
      watcherPermission:
        'Beobachter ist ein autorisierter Benutzer, der schreibgeschützten Zugriff auf das Unterkonto hat.',
      userCenter: 'Benutzercenter',
      SignOut: 'Abmelden',
      accountSetting: 'Kontoeinstellungen',
      poolManagePanel: 'Dashboard',
      watcherMode: 'Beobachtermodus',
      scanToService: 'QR-Code scannen, um Hilfe zu erhalten',
      bitdeer: 'Bitdeer',
      hashnest: 'HashNest',
      explorer: 'Blockchain-Explorer',
      app: 'App',
      getBCHA: 'BCHA-Auszahlung',
      candy: 'Candy',
      alertBar:
        '<b>Am 10. August, 22:00-24:00 Uhr (UTC+8)</b> wird der Mining-Pool von BTC.com eine umfangreiche Wartung durchführen. Einige Funktionen werden nicht verfügbar sein, doch der Mining-Dienst wird nicht beeinträchtigt werden. <a href="https://help.pool.btc.com/hc/en-us/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">Klicken, für Details</a>',
      introTip:
        'Funktionen wie Dashboard, Miner, Einnahmen und Unterkontoeinstellungen sind im "Pool-Dashboard" vereint.',
    },
    nav: {
      'nav-explorer': 'Explorer',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': 'Pool',
      'nav-pool-home': 'Pool Home',
      'nav-pool-data': 'Pool-Daten',
      'nav-pool-candy': 'Candy',
      'nav-pool-tools': 'Tools & Software',
      'nav-pool-cs': 'Kundenservice',
      'nav-wallet': 'Wallet',
      'nav-help': 'Helfen',
      'nav-navigation': 'Blockchain-Navigation',
      'nav-inscriptions': 'Einschreiben',
      'nav-app': 'APP',
      'nav-collaborate': 'kooperieren',
      'nav-brc20': 'BRC-20',
      'nav-tools': 'Werkzeug',
      'nav-tools-address-monitoring': 'Address Monitoring',
      'nav-tools-address-detail': 'Address Txn Details',
      'nav-tools-address-report': 'Address Report',
      'nav-tools-address-increaseminerfee': 'Increase Txn Fees',
      'nav-tools-address-txreverse': 'Transaction Reversal',
      'nav-tools-address-doublespend': 'Txn Double-spending',
      'nav-tools-APIService': 'API Service',
      'nav-tools-accelerate': 'Txn Accelerator(CPFP)',
      'nav-tools-acceleratebatch': 'Batch Txns Accelerator',
      'nav-tools-TransactionBroadcast': 'Transaction Broadcast',
      'nav-tools-special': 'Rare Sats Query',
      'nav-tools-receipt': 'Transaction Receipt',
      'nav-tools-pickupsats': 'Transfer Rare Sats',
      'nav-tools-bulkpayment': 'Batch Payment',
      'nav-tools-address-graph': 'Address Graph',
      'nav-runes': 'Runes Mint',
      'nav-runes-explorer': 'Runes Explorer',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '0 Fees and Giveaway GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: 'Aktuelles Guthaben',
      coinAddress: '{coinType}-Adresse',
      setAddress: 'Bitte geben Sie eine {coinType}-Adresse ein.',
      multAddressEnabled: 'Zahlung mit mehreren Adressen ist aktiviert',
      menu: {
        hide: 'Ausblenden',
        address: 'Wallet-Adresse',
        minPay: 'Mindestzahlung',
        payWithBtc: 'Mit BTC bezahlen',
        earingsHistory: 'Einnahmen',
        shareData: 'Geteilte Konten',
        watcher: 'Beobachter einstellen',
        apikey: 'API KEY',
        alertSettings: 'Alarm einstellen',
        onebuttonSwitch: 'Ein-Klick-Wechseln',
        userCenter: 'Benutzercenter',
        signOut: 'Abmelden',
        delete: 'Löschen',
      },
      confirm: 'OK',
      cacel: 'Abbrechen',
      deleteConfirm: 'Löschen bestätigen',
      cannotDelete: 'Das Unterkonto kann nicht gelöscht werden',
      cannotDeleteView:
        'Das aktuell angezeigte Unterkonto kann nicht gelöscht werden',
      deleteCondition:
        'Unterkonto kann nicht gelöscht werden. <span class="subAccountName">{{accountMessage}}</span>, nur Unterkonten, die die folgenden beiden Bedingungen erfüllen, können gelöscht werden:',
      cannotDeleteReason:
        '1. Die Hashrate des Unterkontos in den letzten 180 Tagen beträgt 0;<br/>2. Die unbezahlte Kryptowährung des Unterkontos (einschließlich Geschenkmünzen) beträgt 0;<br/>3. Das Unterkonto gehört nicht zu irgendwelchen besonderen Unterkonten.',

      deleteName: 'Sie löschen das Unterkonto:',
      deleteInfo:
        '* Nach dem Klicken auf "OK" wird das Unterkonto endgültig gelöscht und kann nicht wiederhergestellt werden;<br/>* Nach dem Löschen können die Hashrate-Kurve, der Einkommensnachweis und andere Daten des Unterkontos nicht mehr abgerufen werden.',
      deleteSuccess: 'Unterkonto erfolgreich gelöscht',
    },
    footer: {
      services: 'Services',
      Products: 'Produkte',
      help: 'Hilfe',
      btcTool: 'BTC-Tool',
      btcSmartAgent: 'BTC Smart Agent',
      btcComFirmware: 'BTC.com Firmware',
      blockchainExplorer: 'Blockchain-Explorer',
      app: 'App',
      apiDocs: 'API Docs',
      vipApplication: 'VIP-Bewerbung',
      cloudMinePoolService: 'Cloud-Mining-Pool Erstellungs-Service',
      miningTutorial: 'Mining-Tutorial',
      FAQ: 'FAQ',
      Announcements: 'Ankündigung',
      ticketSystem: 'Ticket-System',
    },
    pageTitle: {
      home: 'CloverPool(formerly BTC.com), ein besserer Bitcoin-Mining-Pool',
      dashboard: 'Dashboard - CloverPool(formerly BTC.com)',
      miners: 'Miners - CloverPool(formerly BTC.com)',
      earningHistory: 'Einnahmen-Verlauf - CloverPool(formerly BTC.com)',
      earningHistoryForInvite: 'Provisionse - CloverPool(formerly BTC.com)',
      poolStats: 'Pool-Daten - CloverPool(formerly BTC.com)',
      guardian: 'The Guardian-Einladungslink - CloverPool(formerly BTC.com)',
      guardianBind:
        'Verknüpfen des The Guardian Einladungslinks - CloverPool(formerly BTC.com)',
      subAccount: 'Unterkonto erstellen - CloverPool(formerly BTC.com)',
      subAccountManage: 'Unterkontoverwaltung - CloverPool(formerly BTC.com)',
      settings: 'Einstellungen - CloverPool(formerly BTC.com)',
      getCoin: 'Erhalte Coin - CloverPool(formerly BTC.com)',
      tools: 'Tools & Software - CloverPool(formerly BTC.com)',
      bitdeer: 'Bitdeer - CloverPool(formerly BTC.com)',
      watcherExpired:
        'Beobachter-Rechte abgelaufen - CloverPool(formerly BTC.com)',
      watcherIncorrect: 'Beobachter-Link-Fehler - CloverPool(formerly BTC.com)',
      fundAuthorization:
        'Fonds-Zulassungsvereinbarung - CloverPool(formerly BTC.com)',
      app: 'App Download - CloverPool(formerly BTC.com)',
      invite: 'Invitation Reward - CloverPool(formerly BTC.com)',
    },
    breadCrumbs: {
      home: 'Startseite',
      dashboard: 'Dashboard',
      miners: 'Miner',
      earningHistory: 'Einnahmen',
      poolStats: 'Pool-Daten',
      shareAccount: 'Einstellungen für Geteilte Konten',
      guardianBindingInvitation: 'Verknüpfen des The Guardian Einladungslinks',
      guardianBindingLink: 'The Guardian-Kontoverwaltung',
      subAccountManage: 'Unterkontoverwaltung ',
      hiddenSubAccounts: 'Versteckte Unterkonten verwalten',
      settings: 'Einstellungen',
      getCoin: 'Erhalte Coin',
      tools: 'Tools & Software',
      fundAuthorization: 'Fonds-Zulassungsvereinbarung',
    },
    currency: {
      currency: 'EUR',
    },
    allSwitchMode: {
      smart_sha256: 'Smart-Pool',
      otcNotice: `Sie sind dabei, Ihre Hashrate auf Smart-Pool umzustellen. Wenn Sie auf "OK" klicken,erklären Sie damit zur gleichen Zeit, dass Sie die {fundLink} gelesen und ihr zugestimmt haben.`,
      fundLink: `"BTC.com Smart-Pool Service-Vereinbarung"`,
      autoTip: `Die Verwendung des Smart-Pool-Modus bedeutet, dass Sie die Hashrate auf BTC.com hosten, die zwischen BTC, BCH und BSV mit dem größten Gewinn beim Minen für Sie wechselt.`,
      originalTip:
        'Wenn Sie den originalen Währungsmodus wählen, wird SHA256 Hash nur die von Ihnen ausgewählten Coins abbauen, anstatt automatisch zu wechseln.',
    },
    operation: {
      name: 'Vorgang',
      success: 'Vorgang erfolgreich!',
      failed: 'Vorgang fehlgeschlagen!',
    },
    earning: {
      balanceTip:
        'Nachdem die Abrechnung abgeschlossen ist, werden wir die Zahlung in der Regel innerhalb von 24 Stunden abschließen, mit Ausnahme der folgenden Situationen:<br>1.Es ist keine Adresse eingestellt;<br>2.Die Adresse ist festgelegt, aber der kumulierte Abrechnungsbetrag hat die Mindestzahlungsschwelle nicht erreicht;<br>3.Vorgänge wie das Hinzufügen einer Adresse, das Ändern einer Adresse, das Löschen einer Adresse oder das Ändern eines Verhältnisses haben die 48-Stunden-Zahlungsaussetzungsregel ausgelöst;<br>4.Die Risikokontrollregel wird ausgelöst;<br>5.Die Währung erleidet eine harte Gabelung oder einen 51%-Angriff oder andere größere Upgrades oder Unfälle.',
    },
    meta: {
      keywords:
        'bitcoin,pool,mine,mining,btc.top,slushpool,btc,eth,etc,ltc,dcr,bsv,f2pool,antpool,slush,ghash,antminer,bw,pool,via,viabtc',
      description:
        'BTC.com Pool ist eine völlig neue Wahl für Bitcoin-Miner. Der BTC.com Pool hat eine weitaus stabilere Architektur, eine viel bessere Benutzererfahrung, viel niedrigere Gebühren und einen viel besseren Service.',
    },
    gotIt: 'Verstanden',
    showAll: 'Alle anzeigen',
    foldMe: 'Alle ausblenden',
    copied: 'Kopiert',
    more: 'Mehr',
    none: 'Keine',
    on: 'Ein',
    off: 'Aus',
    all: 'ALLE',
    modify: 'Modifizieren',
    submit: 'Absenden',
    ok: 'OK',
    cancel: 'Abbrechen',
    new: 'Hinzufügen',
    address: 'Adresse',
    notFoundTip: 'Leider existiert die gesuchte Seite nicht',
    export: 'Export',
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    smartPool: 'Smart-Pool',
    status: 'Status',
    notice: 'Hinweis',
    yes: 'Ja',
    no: 'Nein',
    coin: 'Coin',
    custom: 'Benutzerdefiniert',
    hide: 'Ausblenden',
    less: 'Weniger',
    details: 'Einzelheiten',
    region: {
      cn: 'Beijing (China)',
      sz: 'Shenzhen (China)',
      us: 'US',
      eu: 'EU',
      sg: 'Singapur',
    },
    LegalStatement:
      'Hiermit bestätige ich Folgendes: Ich bin kein Einwohner von Kuba, Iran, Nordkorea, Syrien, Russland oder anderen Ländern oder Regionen, die Sanktionen unterliegen, die von relevanten Ländern oder Regierungen oder internationalen Behörden verhängt oder durchgesetzt werden, und ich bin nicht auf dem chinesischen Festland. Der von BTC.com bereitgestellte Mining-Pool-Service verstößt nicht gegen Gesetze, Vorschriften und einschlägige Richtlinien des Landes oder der Region, in der ich mich befinde.',
    LegalStatementConfirm:
      'Alle rechtlichen Risiken und Haftungspflichten, die direkt dadurch oder als Folge davon entstehen, dass die Nutzung der von BTC.com angebotenen Services in dem Land oder der Region, in dem bzw. der ich wohne, rechtswidrig sind, trage ausschließlich ich selbst.',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
