import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Checkbox, BackTop } from 'antd';
import Cookie from 'js-cookie';
import TopHeader from 'components/TopHeader';
import { Footer, CookiePolicy } from '@btccomfe/btccomfe-base-ui';
import { BtcModal } from 'components/widget';
import { LOCALES_MAP } from 'common/config/localeConfig';
import { Helmet } from 'react-helmet';
import Ts from 'components/LocaleTranslator';
import './index.scss';
import SvgIcon from 'components/SvgIcon';
import GroupEntry from 'components/GroupEntry';

const TIMEOUT = 600;
@inject('store')
@withRouter
class Layout extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.homepageStore = this.props.store.homepageStore;
    this.judgeIsHome();
  }

  state = {
    isHome: false,
    path: '',
    inProp: false,
    isShowAnnouncement: true,
    statementModalVisible: Cookie.get('hideStatementConfirm') ? false : true,
    statementConfirmed: false,
  };

  judgeIsHome() {
    let isHome = false;
    if (
      this.props.location.pathname === '/' ||
      this.props.location.pathname === '/home' ||
      // i18n path
      Object.keys(LOCALES_MAP).some((lang) => {
        const pathname = this.props.location.pathname.toLowerCase();
        return (
          pathname === '/' + lang.toLowerCase() ||
          pathname === '/' + lang.toLowerCase() + '/home'
        );
      })
    ) {
      isHome = true;
    }

    if (this._mounted) {
      this.setState({
        isHome,
      });
    } else {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.isHome = isHome;
    }
    this.homepageStore.setIsHome(isHome);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const self = this;
    this.setState((state) => ({
      inProp: true,
    }));
    setTimeout(() => {
      self.setState((state) => ({
        inProp: false,
      }));
    }, TIMEOUT);
    this.judgeIsHome();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let path = nextProps.location.pathname;
    return {
      path,
    };
  }

  onClose = (e) => {
    this.setState({
      isShowAnnouncement: false,
    });
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.props.history.listen((location, action) => {
      if (location) {
        this.judgeIsHome();
      }
    });
  }

  handleCheckStatementChange = (e) => {
    this.setState({ statementConfirmed: e.target.checked });
  };
  handleOkStateModal = () => {
    if (this.state.statementConfirmed) {
      this.setState({ statementModalVisible: false });
      Cookie.set('hideStatementConfirm', true, { expires: 365 * 10 });
    }
  };

  render() {
    const { isHome } = this.state;
    const pathname = this.props.location.pathname;
    let splites = pathname.split('/');
    let realPath = pathname;
    if (splites.length > 0) {
      realPath = splites[splites.length - 1];
    }
    const contentClass = ['bitdeer', 'app-download', 'invite'].includes(
      realPath,
    )
      ? 'full-page-content'
      : '';

    return (
      <>
        <Helmet>
          <meta
            name="keywords"
            content={new Ts().getLocaleString('common.meta.keywords')}
          />
          <meta
            name="description"
            content={new Ts().getLocaleString('common.meta.description')}
          />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-ND01MXEXWJ"
          ></script>
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            console.log('gtagggg')

            gtag('config', 'G-ND01MXEXWJ');
          `}</script>
        </Helmet>
        <GroupEntry />
        <TopHeader />
        <div
          className={
            isHome ? 'homepage-content' : `page-content${' ' + contentClass}`
          }
        >
          {this.props.children}
        </div>

        <BtcModal
          visible={this.state.statementModalVisible}
          closable={false}
          onOk={this.handleOkStateModal}
          hideCancelButton={true}
          width={600}
          okButtonProps={{ disabled: !this.state.statementConfirmed }}
          maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
          // centered
        >
          <div className="font-md statement-text">
            {<Ts id="common.LegalStatement" />}
          </div>
          <div>
            <Checkbox
              checked={this.state.statementConfirmed}
              onChange={this.handleCheckStatementChange}
              className="margin-top-lg font-md statement-text"
            >
              <Ts id="common.LegalStatementConfirm" />
            </Checkbox>
          </div>
        </BtcModal>

        <BackTop visibilityHeight={400} className="goto-top">
          <SvgIcon iconName="icon_goto_top" size={40} />
        </BackTop>

        <Footer
          className="page-footer"
          lang={LOCALES_MAP[this.appStore.lang].footerLang}
          explorerBaseUrl={process.env.REACT_APP_EXPLORER_URL}
        />

        <CookiePolicy
          lang={LOCALES_MAP[this.appStore.lang].footerLang}
          style={{ position: 'fixed', zIndex: 999 }}
        />
      </>
    );
  }
}

export default Layout;
